/* eslint-disable no-unused-vars */
import { mapActions } from 'vuex';
import lodash from 'lodash';

const methods = {
  ...mapActions(['login']),
  init() {},
  clickRememberMe(value) {
    localStorage.setItem('rememberMe', value);
  },
  UsernameCanNotEmpty() {
    return !!this.formdata.account || '未输入用户名';
  },
  PasswordCanNotEmpty() {
    return !!this.formdata.password || '未输入密码';
  },
  loginSubmitHandle() {
    // eslint-disable-next-line no-debugger
    // debugger;
    const { login, $refs } = this;
    if ($refs.loginForm?.validate()) {
      this.loading = true;
      const param = lodash.cloneDeep(this.formdata);
      param.iv = {};
      login(param).finally(() => {
        this.loading = false;
      });
    }
  },
};

export default methods;
